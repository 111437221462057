import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    //user
    {
      path: "/",
      name: "user_home",
      component: () => import("./views/user/index.vue"),
      meta: {
        allowedRoles: ["*"],
        hideComponents: true,
      },
    },
    // admin
    {
      path: "/admin",
      name: "home",
      component: () => import("./views/home/index.vue"),
      meta: {
        allowedRoles: ["*"],
      },
    },
    {
      path: "/admin/login",
      name: "login",
      component: () => import("./views/auth/login.vue"),
      meta: {
        hideComponents: true,
      },
    },
    {
      path: "/admin/user/:id?",
      name: "user",
      component: () => import("./views/content/user/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/category/:id?",
      name: "category",
      component: () => import("./views/content/category/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/material/:id?",
      name: "material",
      component: () => import("./views/content/material/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/subject/:id?",
      name: "subject",
      component: () => import("./views/content/subject/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/task/:id?",
      name: "task",
      component: () => import("./views/content/task/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/course/:id?",
      name: "course",
      component: () => import("./views/content/course/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/news/:id?",
      name: "news",
      component: () => import("./views/content/news/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "/admin/role/:id?",
      name: "role",
      component: () => import("./views/content/role/index.vue"),
      meta: {
        allowedRoles: ["admin"],
      },
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/PageNotFound.vue"),
      meta: {
        allowedRoles: ["*"],
      },
    },
  ];
  const router = new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
  // Проверка на доступность страницы
  router.beforeEach((to, from, next) => {
    if (window.user && window.user.roles) {
      let roles = window.user.roles.map((r) => r.name);
      if (!(to.meta.allowedRoles && to.meta.allowedRoles.some((r) => r === "*" || roles.includes(r)))) {
        next(false);
        return;
      }
    }
    next();
  });

  return router;
}
