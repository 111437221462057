<template>
  <div class="modal-body add-modal">
    <div class="modal-body__header">
      <span class="modal-body__title">Добавить контент</span>
      <button type="button" class="modal-body__close">
        <Icon name="close-line" category="system" class="icon" />
      </button>
    </div>
    <div class="modal-body__content">
      <ul class="add-modal__list">
        <li v-for="(link, i) in list.filter((l) => canShowLink(l.allowedRoles))" :key="i">
          <router-link :to="link.route">{{ link.title }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/IconComponent.vue";

export default {
  name: "AddModal",
  components: { Icon },
  data() {
    return {
      list: [
        {
          route: {
            name: "category",
          },
          title: "Направление",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "course",
          },
          title: "Курс",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "material",
          },
          title: "Учебный материал",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "subject",
          },
          title: "Предмет",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "task",
          },
          title: "Задача",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "news",
          },
          title: "Новость",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "role",
          },
          title: "Роль",
          allowedRoles: ["admin"],
        },
        {
          route: {
            name: "user",
          },
          title: "Учитель",
          allowedRoles: ["admin"],
        },
      ],
    };
  },
  methods: {
    canShowLink(roles) {
      if (window.user && window.user.roles) {
        return roles.some((r) => window.user.roles.map((r) => r.name).includes(r));
      }
      return false;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/colors.styl"
.add-modal {
  &__list {
    display grid
    width 100%

    li {
      display flex
      width 100%

      &:not(:last-child) {
        border-bottom 1px solid rgba($dark, .2)
      }

      a {
        width 100%
        padding 5px 15px
        color $dark
        font-size 1.25em

        &:hover {
          background rgba($main_color, .2)
        }
      }
    }
  }
}
</style>
