import Vue from "vue";
import moment from "moment";
Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});
Vue.filter("humanDate", (date) => {
  return moment(date).format("DD MMMM YYYY");
});
Vue.filter("robotDate", (date) => {
  return moment(date);
});
Vue.filter("humanDateRu", (date) => {
  moment.locale("ru");
  if (moment(date).format("LL") === moment(Date.now()).format("LL")) {
    return moment(date).format("LT");
  } else {
    return moment(date).format("DD MMMM YYYY");
  }
});
Vue.filter("humanDateHoursRu", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY\u00A0 HH:mm");
});

Vue.filter("humanDateSliderRu", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY, LT");
});

Vue.filter("humanDatePosterRu", (date, dateEnd) => {
  moment.locale("ru");
  if (dateEnd != null) {
    return moment(date).format("DD MMMM YYYY") + " - " + moment(dateEnd).format("DD MMMM YYYY");
  } else {
    return moment(date).format("DD MMMM YYYY");
  }
});

/**
 * Форматирование даты
 */
Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

/**
 * Форматирование цены
 */
Vue.filter("prettyPrice", (price) => {
  return Number(price).toLocaleString("ru", {
    maximumFractionDigits: 2,
  });
});

Vue.filter("image", (entity) => {
  if (entity) {
    return location.origin + entity.url;
  }
  return "/distro/images/no-image.jpg";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.img) {
    return entity.img.alt;
  }
  return "";
});
