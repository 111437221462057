<template>
  <div id="app">
    <HeaderComponent v-if="!$route.meta.hideComponents" />
    <Transition mode="out-in" name="page">
      <RouterView :class="{ 'main--fullheight': $route.meta.hideComponents }" :key="$route.fullPath" />
    </Transition>
    <ModalsComponent v-if="!$route.meta.hideComponents" />
    <Notifications v-if="!$route.meta.hideComponents" position="bottom right" />
  </div>
</template>

<script>
import HeaderComponent from "./components/header";
import ModalsComponent from "./components/modals";

export default {
  name: "App",
  components: {
    ModalsComponent,
    HeaderComponent,
  },
};
</script>
